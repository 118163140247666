import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth, AuthProvider } from './AuthProvider';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from './Dashboard';
import ProductsPage from './ProductsPage';

const App: React.FC = () => {
  const { user, signInWithGoogle } = useAuth();

  return (
    <Router>
      <div>
        {!user ? (
          <div className="p-6 bg-white rounded shadow-lg">
            <h1 className="text-xl font-semibold mb-4">Login</h1>
            <button
              onClick={signInWithGoogle}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Sign in with Google
            </button>
          </div>
        ) : (
          <Routes>
            <Route path="/login" element={<Navigate to="/" replace />} />
            <Route
              path="/"
              element={<ProtectedRoute element={<Dashboard />} />}
            >
              <Route index element={<div>Welcome to the Dashboard</div>} />
              <Route path="products" element={<ProductsPage />} />
              <Route path="nutrition-facts" element={<div>Nutrition Facts Page</div>} />
              <Route path="prices" element={<div>Prices Page</div>} />
              <Route path="prices/history" element={<div>Prices History Page</div>} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        )}
      </div>
    </Router>
  );
};

export default function WrappedApp() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const Dashboard: React.FC = () => {
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const { signOut } = useAuth(); // Access signOut function from auth context

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      setDarkMode(true);
    }
  }, []);

  return (
    <div className={`min-h-screen flex ${darkMode ? 'dark' : ''}`}>
      {/* Sidebar */}
      <aside className="w-20 md:w-56 lg:w-64 bg-gray-800 dark:bg-gray-900 text-white flex-shrink-0">
        <div className="p-4 md:p-6">
          <h1 className="text-lg md:text-xl font-semibold">Admin</h1>
          <h1 className="hidden md:block md:text-xl font-semibold">Dashboard</h1>
        </div>
        <nav className="p-2 md:p-6">
          <Link to="/" className="block w-full text-left py-2 px-4 rounded hover:bg-gray-700">
            Dashboard
          </Link>
          <Link to="/products" className="block w-full text-left py-2 px-4 rounded hover:bg-gray-700">
            Products
          </Link>
          <Link to="/nutrition-facts" className="block w-full text-left py-2 px-4 rounded hover:bg-gray-700">
            Nutrition Facts
          </Link>
          <Link to="/prices" className="block w-full text-left py-2 px-4 rounded hover:bg-gray-700">
            Prices
          </Link>
          <Link to="/prices/history" className="block w-full text-left py-2 px-4 rounded hover:bg-gray-700">
            Prices History
          </Link>
          <button onClick={signOut} className="block w-full text-left py-2 px-4 rounded hover:bg-gray-700">
            Logout
          </button>
        </nav>
      </aside>
      {/* Main Content */}
      <div className="flex-1 flex flex-col dark:text-white">
        {/* Header */}
        <header className="bg-white dark:bg-gray-800 shadow py-4 px-6 flex justify-between items-center dark:text-white">
          <h1 className="text-lg md:text-xl font-semibold">Dashboard</h1>
        </header>
        {/* Content */}
        <main className="flex-1 p-4 md:p-6">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;

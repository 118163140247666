import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { IProduct } from '../interfaces/IProduct';
import ProductCard from './components/ProductCard';
import PaginationControl from './components/PaginationControl';
import { Row, Col } from 'react-bootstrap';

const ProductsPage: React.FC = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [, setLoading] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>(1);
  const productsPerPage = 48;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page')) || 1;

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);

      const { data, error, count } = await supabase
        .schema('scrappers')
        .from('products')
        .select(`*,
          product_images (small_url)
        `, { count: 'exact' })
        .range((currentPage - 1) * productsPerPage, currentPage * productsPerPage - 1);

      if (error) {
        console.error('Error fetching products:', error.message);
        setLoading(false);
        return;
      }

      const productsWithImages: IProduct[] = data.map(product => ({
        product_id: product.product_id,
        brand: product.brand,
        title: product.title,
        description: product.description,
        package_sizing: product.package_sizing,
        offer_type: product.offer_type,
        offer_id: product.offer_id,
        action: product.action,
        is_variant: product.is_variant,
        link: product.link,
        product_vendor: product.product_vendor,
        product_variant: product.product_variant,
        text_badge: product.text_badge,
        variant_theme: product.variant_theme,
        article_number: product.article_number,
        uom: product.uom,
        sponsored_creative: product.sponsored_creative,
        is_sponsored: product.is_sponsored,
        is_complementary_sponsored: product.is_complementary_sponsored,
        complementary_sponsored_badge_text: product.complementary_sponsored_badge_text,
        product_badge: product.product_badge,
        image_url: product.product_images[0]?.small_url || '',
      }));

      setProducts(productsWithImages);
      setTotalPages(Math.ceil((count || 0) / productsPerPage));
      setLoading(false);
    };

    fetchProducts();
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    navigate(`?page=${page}`);
  };

  return (
    <>
      <PaginationControl currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      <Row>
        {products.map((product) => (
          <Col key={product.product_id} lg={2} className="mb-4">
            <ProductCard product={product} />
          </Col>
        ))}
      </Row>
      <PaginationControl currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </>
  );
};

export default ProductsPage;

export const getOAuthRedirectUrl = (): string => {
  let url =
    process.env.REACT_APP_SITE_URL ?? // Set this to your site URL in production env.
    process.env.REACT_APP_VERCEL_URL ?? // Automatically set by Vercel.
    'http://localhost:3000/';

  console.log('@theUrl??!!', url);

  // Make sure to include `https://` when not localhost.
  url = url.startsWith('http') ? url : `https://${url}`;
  // Make sure to include a trailing `/`.
  url = url.endsWith('/') ? url : `${url}/`;

  return url;
};
import React from 'react';
import { Pagination } from 'react-bootstrap';

interface PaginationControlProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const PaginationControl: React.FC<PaginationControlProps> = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const jumpPages = 5; // Number of pages to jump when clicking ellipsis

  const getPaginationItems = () => {
    const items = [];
    const maxItemsToShow = 5; // Number of page items to show at once

    let startPage = Math.max(1, currentPage - Math.floor(maxItemsToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxItemsToShow - 1);

    if (endPage - startPage < maxItemsToShow - 1) {
      startPage = Math.max(1, endPage - maxItemsToShow + 1);
    }

    items.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
    );
    items.push(
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    if (startPage > 1) {
      items.push(
        <Pagination.Item key="1" onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        items.push(
          <Pagination.Ellipsis key="start-ellipsis" onClick={() => handlePageChange(Math.max(1, currentPage - jumpPages))} />
        );
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(
          <Pagination.Ellipsis key="end-ellipsis" onClick={() => handlePageChange(Math.min(totalPages, currentPage + jumpPages))} />
        );
      }
      items.push(
        <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    items.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    );
    items.push(
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return items;
  };

  return (
    <Pagination className="justify-content-center">
      {getPaginationItems()}
    </Pagination>
  );
};

export default PaginationControl;

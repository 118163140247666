import React from 'react';
import { Card, Button } from 'react-bootstrap';

interface ProductCardProps {
  product: {
    product_id: string;
    title: string;
    description: string;
    image_url: string;
  };
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const { product_id, title, description, image_url } = product;
  const truncatedDescription = description.length > 100 ? description.substring(0, 100) + '...' : description;

  return (
    <Card className="h-100 d-flex flex-column product-card">
      {image_url && <Card.Img variant="top" src={image_url} alt={title} />}
      <Card.Body className="d-flex flex-column">
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">SKU: {product_id}</Card.Subtitle>
        <Card.Text className="flex-grow-1">
          {truncatedDescription}
        </Card.Text>
        <Button variant="primary" className="mt-auto">View Product</Button>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
